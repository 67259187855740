import axios from 'axios'

export class Creche {
  static async cadastrarRespostas (array) {
    return axios.post('creche/respostas/todos', {
        array: array,
    })
  }
  static async respostasCrecheShowAll (perguntas_creche_id) {
    return axios.get('creche/resp/'+perguntas_creche_id);
  }
}
